<template>
  <!-- <v-app> -->
    <!-- När jag oroar mig över css i framtiden kan jag börja med att kolla här...  -->
    <div>
    <template v-if="!loading">
      <dashboard-core-app-bar />
      <dashboard-core-drawer />
      <dashboard-core-view />
      <SaveFooter />
    </template>
    <template v-else>
        <v-main class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mt-12"
            size="50" />
        </v-main>
    </template>
    </div>
  <!-- </v-app> -->
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('@/views/core/AppBar'),
      DashboardCoreDrawer: () => import('@/views/core/Drawer'),
      DashboardCoreView: () => import('@/views/core/View.vue'),
      SaveFooter: () => import('@/views/core/SaveFooter.vue'),
    },

    data: () => ({
      loading: true,
    }),
    async mounted(){
      // APP AUTH ROUTE MOUNT
      console.log('auth route index.vue mount');
      this.$store.commit('app/SET_DRAWER', this.$vuetify.breakpoint.mdAndUp)
      console.log(this.$vuetify.breakpoint, this.$vuetify.breakpoint.mdAndUp);
      // const user = this.$store.state.user.userProfile
      this.loading = false
    },
  }
</script>

